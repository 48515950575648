import NavbarPost from "../components/Navbar/NavbarPostlogin";
import EditMascot from "../components/EditMascot/EditMascot";
import Footer from "../components/Footer/Footer";

export default function EditMascotPage() {
  return (
    <div>
      <NavbarPost />
      <EditMascot />
      <Footer />
    </div>
  );
}
