import NavbarPost from "../components/Navbar/NavbarPostlogin";
import BadgeInfo from "../components/BadgeInfo/BadgeInfo";
import Footer from "../components/Footer/Footer";

export default function EditMascotPage() {
  return (
    <div>
      <NavbarPost />
      <BadgeInfo />
      <Footer />
    </div>
  );
}
